<template>
	<el-row class="main-bottom">
		<div
			style="background-color: #434343;align-items: center;display: flex;flex-direction: column;justify-content: center;">
			<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 20, offset: 2 }" :lg="{ span: 16, offset: 0 }"
				style="">

				<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 12 }" :lg="{ span: 12 }" style="">
					<div style="display: flex;padding: 10px 0;align-items: center;">
						<img style="width: 46px;" class="" :src="require('@/assets/images/logo.png')" />
						<div style="color: #FFFFFF;margin-left: 5px;">
							<div style="font-size: 28px;line-height: 42px;">云南德合信息科技有限公司</div>
							<div
								style="font-size: 15px;sline-height: 22px;letter-spacing: 0.02em;text-transform: uppercase;">
								Dehe Business Service Information Technology Co., Ltd</div>
						</div>
					</div>
				</el-col>
				<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 12 }" :lg="{ span: 12 }" style="text-align: right;margin-top: 22px;">
					<div
						style="font-size: 15px;line-height: 22px;letter-spacing: 0.02em;text-transform: uppercase;color: #666666;">
						<div>服务热线：18468119031</div>
						<div style="">云南德合信息科技有限公司 </div>
					</div>
				</el-col>

			</el-col>
			<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 20, offset: 2 }" :lg="{ span: 16, offset: 0 }"
				style="">
				<div style="width: 100%; height: 1px;background: rgba(255, 255, 255, 0.2);"></div>

				<div
					style="width: 100%;text-align: center; padding: 10px 0;font-size: 15px;line-height: 22px;letter-spacing: 0.02em;text-transform: uppercase;color: #666666;">
					Copyright©2022 云南德合信息科技有限公司 版权所有
				</div>
				
				<div style="width:300px;margin:0 auto; padding:20px 0;">
					<a target="_blank" href="https://beian.miit.gov.cn/"
						style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
						<img :src="require('@/assets/images/icon_gh.png')" style="float:left;" />
						<p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">滇ICP备2022006537号-1</p>
					</a>
				</div>
			</el-col>

		</div>
	</el-row>
</template>
<script>
	export default {
		data() {
			return {
				record: ''
			}
		},
		methods: {
			setRecord() {
				var curWwwPath = window.location.host;
				if (curWwwPath.indexOf('issdn') > -1) {
					this.record = '湘ICP备18023659号-1'
				} else if (curWwwPath.indexOf('sxzsdn') > -1) {
					this.record = '湘ICP备18023659号-2'
				} else if (curWwwPath.indexOf('sxzwan') > -1) {
					this.record = '湘ICP备18023659号-3'
				} else if (curWwwPath.indexOf('xspeeder') > -1) {
					this.record = '湘ICP备18023659号-4'
				} else if (curWwwPath.indexOf('dwcache') > -1) {
					this.record = '湘ICP备18023659号-5'
				} else if (curWwwPath.indexOf('netsxz') > -1) {
					this.record = '湘ICP备18023659号-6'
				} else if (curWwwPath.indexOf('sxzros') > -1) {
					this.record = '湘ICP备18023659号-7'
				} else if (curWwwPath.indexOf('i1608') > -1) {
					this.record = '湘ICP备18023659号-8'
				} else if (curWwwPath.indexOf('sxzrouter') > -1) {
					this.record = '湘ICP备18023659号-9'
				}
			}
		},
		created() {
			this.setRecord();
		}
	}
</script>
<style lang="scss" scoped>
	.main-bottom {
		width: 100%;
	}

	.main-bottom .main-bottom-conts {
		width: 100%;
		background-color: #282828;
		padding: 50px 10px 30px;
		box-sizing: border-box;
		overflow: hidden;
	}

	.main-bottom .main-bottom-conts .item-box {
		margin-bottom: 20px;
		min-height: 176px;
	}

	.main-bottom .main-bottom-conts .title {
		font-size: 16px;
		color: #fff;
		line-height: 1;
		padding-bottom: 16px;
	}

	.main-bottom .main-bottom-conts .title.zh {
		padding-bottom: 12px;
	}

	.main-bottom .main-bottom-conts .title.en {
		font-size: 10px;
	}

	.main-bottom .main-bottom-conts li {
		list-style: none;
		line-height: 1;
		padding-bottom: 12px;
		font-size: 12px;
		color: #b3b3b3;
	}

	.main-bottom .main-bottom-conts li a {
		font-size: 12px;
		color: #b3b3b3;
	}

	.main-bottom .main-bottom-conts li a:hover {
		text-decoration: underline;
		color: #fff;
	}

	.main-bottom .main-bottom-conts .item-box.code {
		text-align: center;

		img {
			width: 115px;
		}
	}

	.main-bottom .main-bottom-conts .codeimg {
		vertical-align: middle;
		width: 115px;
	}

	.main-bottom .main-bottom-conts .codetext {
		font-size: 12px;
		color: #b3b3b3;
		line-height: 30px;
	}

	.main-bottom .main-bottom-copyright {
		width: 100%;
		background-color: #1d1d1d;
		font-size: 12px;
		text-align: center;
		color: #757575;
	}

	.main-bottom .main-bottom-copyright p {
		display: inline-block;
		line-height: 40px;
		margin-right: 12px;

		&:last-child {
			margin-right: 0;
			display: block;
			text-align: center;
			line-height: 1;
			padding-bottom: 12px;

			a {
				display: inline-block;
				padding-left: 24px;
				background-size: 20px;
			}
		}
	}

	.main-bottom .main-bottom-copyright p a {
		line-height: 20px;
		color: #757575;

		&:hover {
			color: #ababab;
			text-decoration: underline;
		}
	}

	@media screen and (max-width: 767px) {
		.main-bottom .main-bottom-conts .item-box.code {
			text-align: left;
		}

		.main-bottom .main-bottom-copyright p {
			display: block;
			line-height: 20px;
			margin-right: 0;
		}
	}
</style>
