<template>
	<el-row class="main-sidebar-nav">
		<el-col class="hidden-sm-and-up" style="padding: 10px;" :xs={span:3}>
			<el-button icon="el-icon-menu" circle @click="navflag = !navflag"></el-button>
		</el-col>
		<el-col class="logo-box" :xs={span:0} :sm={span:6} :md={span:5,offset:2} :lg={span:5,offset:4}>
			<div class="imgbox">
				<img style="" src="@/assets/images/logo1.png">
			</div>
			<!-- <div class="logo-title" style="color: #3B3B3B;display: flex;flex-direction: column;align-items: center;">
				<div style="font-weight: 500;font-size: 22px;line-height: 33px; letter-spacing: 0.03em;">湖南云南德合信息科技有限公司</div>
				<div style="font-size: 10px; line-height: 15px;">HUNAN DEHE BUSINESS SERVICE TECHNOLOGY CO., LTD</div>
			</div> -->
		</el-col>

		<el-col :class="[this.navflag?'nav-hide':'']" :xs={span:21} :sm={span:18} :md={span:15} :lg={span:11}>
			<el-menu :default-active="$route.path" router class="el-menu-demo" mode="horizontal">

				<el-menu-item class="main-size" index="/main">首页</el-menu-item>


				<el-menu-item class="main-size" index="/main/aboutUS">数字营销</el-menu-item>

				<el-menu-item class="main-size" index="/main/service">智能硬件</el-menu-item>

				<el-menu-item class="main-size" index="/main/product">智能软件</el-menu-item>

				<el-menu-item class="main-size" index="/main/aboutAPP">APP</el-menu-item>

				<el-menu-item class="main-size" index="/main/contactUS">刷脸支付</el-menu-item>

				<el-menu-item class="main-size-right"
					style="position: absolute;right: 0;padding-right: 20px;color: #EC9023;align-items: center;"
					index="">
					<img src="@/assets/images/mainTop_phone.png">
					<img style="margin: 0 10px;" src="@/assets/images/mainTop_1.png">
					18468119031</el-menu-item>

			</el-menu>
		</el-col>
	</el-row>
</template>
<script>
	export default {
		data() {
			return {
				navflag: true
			}
		}
	}
</script>
<style lang="scss" scoped>
	.logo-box{
		margin-top: 25px;
		display: flex;
		flex-direction: row;
		align-items: center;
		
		.imgbox{
			
			img{
				width: 328px;
				height: 60px;
			}
		}
	}
	.main-size {
		font-size: 15px;
	}

	.main-size-right {
		font-size: 15px;
		font-weight: 600;
	}

	.main-sidebar-nav {
		height: 100%;
		overflow: hidden;
	}

	.main-sidebar-nav .el-menu--horizontal>.el-menu-item.is-active,
	.main-sidebar-nav .el-menu--horizontal .el-menu .el-menu-item.is-active {
		color: #EC9023 !important;
	}

	.main-sidebar-nav .el-menu.el-menu--horizontal {
		border-bottom: 0;
	}

	.nav-bbs {
		border: 1px solid #909399;
		padding: 3px 3px 3px 20px;
		background-position-y: center;
		background-position-x: 3px;
		font-size: 12px;
	}

	@media screen and (max-width: 767px) {
		.main-sidebar-nav .logo-box {
			width: 80%;
			padding: 5px 0;
			justify-content: center;
			position: relative;
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-top: 0;
		}

		.main-sidebar-nav .logo-title {
			margin-left: 10px;
		}
		.main-size-right {
			display: none;
		}
	}

	@media screen and (min-width: 768px) {
		.main-sidebar-nav .logo-box {
			padding: 5px 0;
			position: relative;
			display: flex;
			flex-direction: row;
			align-items: center;
		}

		.main-sidebar-nav .logo-title {
			margin-left: 60px;
		}

		.main-sidebar-nav .logo-box img {
			position: absolute;
			top: 50%;
			transform: translate(10px, -50%);
		}

		.main-sidebar-nav .el-menu-demo li {
			width: 14%;
			text-align: center;
		}

		.main-sidebar-nav .el-menu--horizontal>.el-submenu .el-submenu__icon-arrow {
			margin-left: 4px !important;
		}
	}
</style>
<style lang="scss">
	.main-sidebar-nav .nav-hide {
		display: none;
	}

	.el-menu--popup {
		min-width: 120px !important;
	}

	@media screen and (max-width: 767px) {

		.main-sidebar-nav .el-menu-item,
		.main-sidebar-nav .el-menu--horizontal>.el-submenu .el-submenu__title {
			height: 40px !important;
			line-height: 40px !important;
		}

		.main-sidebar-nav .el-menu-item,
		.main-sidebar-nav .el-submenu__title {
			padding: 0 10px !important;
		}

		.main-sidebar-nav .el-menu--horizontal>.el-submenu .el-submenu__icon-arrow {
			margin-left: 4px !important;
		}
	}

	@media screen and (min-width: 768px) {
		.main-sidebar-nav .nav-hide {
			display: block;
		}

		.main-sidebar-nav .el-menu-item,
		.main-sidebar-nav .el-submenu__title {
			padding: 0 !important;
		}

		.main-sidebar-nav .el-menu--horizontal>.el-submenu .el-submenu__icon-arrow {
			margin-left: 4px !important;
		}

		.el-menu--horizontal .el-menu .el-menu-item {
			padding: 0 10px !important;
		}
	}
</style>
